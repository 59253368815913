import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import "./mode-buttons.css";
import Tooltip from "@mui/material/Tooltip";
import { useExtensionContext } from "../../core/ExtensionContext";
import { EditorMode, useEditorContext } from "../../core/EditorContext";
import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { FolderOpen } from "@mui/icons-material";

export function ModeButtons() {
  const { sdk, field, setField, clearUndo, setThumbUrl } =
    useExtensionContext();
  const { mode, changeMode, clearAi, setDrawerVisible } = useEditorContext();

  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  const showButtons = mode === EditorMode.Initial;
  const hasImage = field && field.image.id;

  const modeButton = async (mode: EditorMode): Promise<void> => {
    if (sdk && field && setField && clearUndo) {
      switch (mode) {
        case EditorMode.Swap:
          const image = await sdk.mediaLink.getImage();
          const asset = await sdk.assets.getById(image.id);

          setThumbUrl(asset.thumbURL);

          field.image = image;
          field.poi = {} as any;
          field.hotspots = [];
          field.polygons = [];

          clearUndo();
          setField();
          changeMode(EditorMode.EditorPoi);
          break;
        case EditorMode.Delete:
          field.image = { _empty: true } as any;
          field.poi = {} as any;
          field.hotspots = [];
          field.polygons = [];
          clearUndo();
          setField();
          break;
        default:
          changeMode(mode);
          setDrawerVisible(true);
          break;
      }
    }
  };

  if (!showButtons) {
    return <></>;
  }

  return (
    <Box
      className="amp-mode-buttons-wrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!hasImage && (
        <Grid
          container
          className="amp-mode-buttons-instructions"
          style={{ opacity: hover ? 0 : 1, width: 420 }}
          spacing={2}
        >
          <Grid item xs={2}>
            <FolderOpen fontSize="large" />
          </Grid>
          <Grid item xs={10}>
            <div>
              <Typography variant="subtitle1">
                Set image focal point & shoppable hotspots
              </Typography>
              <Typography variant="body2">
                Choose an image to get started
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
      <div className="amp-mode-buttons">
        {hasImage && (
          <>
            <Tooltip title="Edit image & focal point">
              <Fab onClick={() => modeButton(EditorMode.EditorPoi)}>
                <EditIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Replace">
              <Fab
                onClick={() => {
                  modeButton(EditorMode.Swap);
                  clearAi();
                }}
              >
                <SwapHorizIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Remove">
              <Fab
                onClick={() => {
                  modeButton(EditorMode.Delete);
                  clearAi();
                }}
              >
                <DeleteIcon />
              </Fab>
            </Tooltip>
          </>
        )}
        {!hasImage && (
          <Tooltip title="Set image">
            <Fab onClick={() => modeButton(EditorMode.Swap)}>
              <AddIcon />
            </Fab>
          </Tooltip>
        )}
      </div>
    </Box>
  );
}
